<template>
<div>
<div class="mask"></div>
<div>
  
  <div class="LoginBox" v-if="status === 1">
    <div class="LoginBox-Box"> 
       <el-form :model="form" ref="form" :rules="rules" label-width="0" :inline="false" size="normal" key="1">
          <el-form-item prop="name">
            <el-input v-model="form.name" placeholder="请输入账号" @input="changeInput(e)"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="form.password" @keyup.enter.native="onSubmit" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login iconfont My-new-iconxuanzhonghaoyou" type="primary" @click="onSubmit">登录</el-button>
          </el-form-item>
      <el-form-item>


        
<el-row type="flex" class="row-bg" justify="space-around">
    <el-button type="success" round :span="12" >
      <!-- <a href="/reg">      注册账号</a> -->
      <router-link to="/reg">注册账号</router-link>

    </el-button>
  
  <el-button type="primary" round :span="12" @click="Gohome">
        回到主页
    </el-button>
</el-row>
      </el-form-item>
    </el-form>
    </div>
   
  </div>

  <!-- 全屏等待加载 -->
  <!-- <div v-loading.fullscreen.lock="loading"></div> -->
</div>
</div>
</template>

<script>
import axios from 'axios';
  export default {
    data() {
     //确认密码的规则
         var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.regform.checkPass !== '') {
            this.$refs.regform.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.regform.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

      return {
        loading:false,
        form:{
          name:'',
          password:''
        },
        regform:{
          regname:'',
          nickname:'',
          pass:'',
          checkPass:''
        },

        status: 1,

         rules: {
          name: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
          ],
        },

         regrules: {
          regname: [
            { required: true, message: '请输入您的用户名', trigger: 'blur' },
            { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
          ],
          nickname:[
             { required: true, message: '请输入您的昵称', trigger: 'blur' },
            { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
          ],
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ]
        }

      }
    },
    methods:{
      // 去主页
      Gohome(){
        this.$router.push({name:'home'})
        // this.$router.push('/')
      },
    // 登录
    onSubmit(){
        this.$refs['form'].validate((res) => {
          if(!res) {
            this.$message.error('账号或密码未输入')
            return;
          }
          axios.post('/login',this.form).then(res => {
            // console.log(res)
            // console.log(res.data)
            this.$message({
              message: '登录成功',
              type:'success'
            });
            this.$store.commit('changIsSignIn',1)
            // console.log(res.data, '11111111')
            localStorage.setItem('nickname', res.data.nickname)
            localStorage.setItem('username', res.data.name)
            this.$store.commit('setToken',res.data.token)
            localStorage.setItem('getToken',res.data.token)
            localStorage.setItem('isLoginStatus',1)
            localStorage.setItem('userimg', res.data.img)
            this.$router.go(0)
            // this.$router.push('/')
          }).catch(res => {
            this.$message({
              message: '账号或密码错误',
              type:'error'
            });
          })
        })
      },
      changeInput(evenet){
        console.log(event,'event')
        this.$forceUpdate()
      }
    },
    // created(){
    //   this.form.name = '',
    //   this.form.password = ''
    // }
  }
</script>

<style lang="scss" scoped>
.register,
.LoginBox {
  width: 400px;
  height: 555px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
  padding: 15px;
  .registerBox,
  .LoginBox-Box {
    margin-top: 30%;
  }
  .login {
    width: 100%;
  }
  .sign {
    font-size: 25px;
    color: rgb(18, 217, 243);
    font-weight: 600;
    margin-right: 15px;
  }
}

.el-input--normal {
  position: relative;
  overflow: hidden;
}
.el-input--normal::before {
    content: '';
    position: absolute;
    left: 0;
    height: 3px;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to right,white,#3498db,#1abc9c,
    #8e44ad,#e67e22,#e74c,white);
    transform: translateX(-100%);
    transition: 1s;
  }
  .el-input--normal:hover::before {
    transform: translateX(100%);
  }
.mask{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
        background: rgba(255, 255, 255, .2);
    box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, .3);
}
</style>